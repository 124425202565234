<template>
  <div>
    <div class="mainpage-header">
      <div style="vertical-align: baseline; float: left">
        <router-link to="/">

          <img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png">

        </router-link>
      </div>
      <div style="float: right;">
        <router-link to="/freelancer-bul" style="padding-right: 40px;" class="nohover">
          <span class="homeHeaderLink">Keşfet</span>
        </router-link>
        <router-link to="/login" class="nohover">
          <span class="homeHeaderLink">Giriş</span>
        </router-link>
        <router-link to="/register">
          <button class="super-button-green"
                  style="width: 150px; min-width: 150px;height: 44px;border-radius: 2px; font-weight: 600; font-size: 16px;  padding: 8px 10px 10px; margin-left: 40px;">
            Kaydol
          </button>
        </router-link>
      </div>
    </div>

    <div class="mainpage-body">
      <h1 class="homeH1">
        Yetenekli freelancerlarla<br><span>daha çok iş yapın.</span>
      </h1>
      <p class="header-info">
        Bionluk, yetenekli freelancerların kendilerine uygun işletmeler ve girişimlerle<br>
        buluştuğu, topluluk mantığıyla ​i​şleyen ​​özgürlükçü bir çalışma platformudur.
      </p>

      <div class="get-started-body">


        <button class="super-button get-started-button" style="" @click="openOnboardingModal()">Hadi başla</button>

      </div>


      <div class="mainpage-illustration">
        <h1 style="margin-bottom: 0px!important;">Neden <span style="font-weight: 600;">Freelancer?</span></h1>
        <p class="header-info" style="margin-bottom: 30px; margin-top: 0px;">Geleceğin "çalışma" modeliyle bugünden tanışın.</p>
        <div class="illustration-body">
          <div class="illustration-inner" style="margin-right: 41px;">
            <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/search_bi.png"/>
            <p class="illustration-main-paragraph">Sınırsızlık, Özgürleştirir
            </p>
            <p class="illustration-paragraph">
              Sınırsız düşünebilmen için bütün sınırları kaldırdık. Artık yalnızca bulunduğun bölgeden değil dünyanın her yerinden global yeteneklerle,
              özgürce çalışabilmen için...

            </p>
          </div>
          <div class="illustration-inner">
            <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/contact-order_bi.png"/>
            <p class="illustration-main-paragraph">Bakış Açını Genişlet
            </p>
            <p class="illustration-paragraph">
              El elden, göz gözden üstündür. Hedef kitleni ne kadar genişletmek istersen, o kadar farklı gözle bakmayı öğrenirsin. Biz senin için her
              pencereyi açtık, bakmak sana kaldı.
            </p>
          </div>
          <div class="illustration-inner" style="margin-left: 41px;">
            <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/done_bi.png"/>
            <p class="illustration-main-paragraph">Aklın Yolu Free</p>
            <p class="illustration-paragraph">
              Becerikli bir işveren, kaynaklarını en verimli nasıl kullanacağını bilen iş verendir. Kaynaklarını olabildiğince verimli kullanmak adına
              freelancer’lara şans verin; göreceksin ki her akıl, her işe değer katar.
            </p>
          </div>
        </div>

        <div class="mainpage-learnmore-div">
          <router-link style="text-decoration: none" to="/register">Hemen Kaydol</router-link>
        </div>
      </div>

    </div>


    <div v-if="blogViews.length" class="mainpage-marketplace-body" style="min-width: 1170px; background-color: #f4f5f7">
      <h1>
        Bionluk <span style="font-weight: 600;">Topluluk</span>
        <br><span style="margin-top: 17px; font-size: 22px; font-weight: 300; color: #8b95a1;">
        Topluluğumuz bizim en büyük değerimiz. Bizi daha yakından tanıyın.
      </span>
      </h1>

      <div style="width: 1170px; margin: 0 auto 60px; ">
        <div style="display: flex; justify-content: space-between">
          <BlogView v-for="(blogView, index) in blogViews" :blog-view="blogView" :key="blogView.pathToGo + index" />
        </div>
      </div>

      <div class="mainpage-learnmore-div">
        <router-link
          @click.native="setWhereFrom"
          @contextmenu.native="setWhereFrom"
          style="text-decoration: none;" to="/blog">Tüm Yazılar</router-link>
      </div>
      <div class="hiddendiv>"></div>
      <div class="hiddendiv>"></div>

    </div>

    <div class="mainpage-marketplace-body" style="background-color: white">
      <h1>
        Bionluk <span style="font-weight: 600;">Freelancerları</span>
        <br><span style="margin-top: 17px; font-size: 22px; font-weight: 300; color: #8b95a1;">
        Kendi alanlarında uzman, özenle seçilmiş freelancerlarımızla tanışın.
      </span>
      </h1>

      <div v-if="gigs.length" class="marketplace-gigs-container">
        <div style="display: flex; margin-left: -30px; text-align: left" >
          <singleGig :impression-u-u-i-d="impressionUUID" :clicked-from="'default'" v-for="(gig, gindex) in gigs" :key="gindex" :item="gig" style="margin-right: 30px;"/>
        </div>
      </div>


      <div class="marketplace-bottom-div" style="margin-top: 40px;">
        <router-link to="/freelancer-bul">
          <button class="morenmore">Daha Fazlası</button>
        </router-link>
      </div>

    </div>



    <div class="mainpage-bottom">
      <h1>Geleceğin "çalışma" modeliyle bugünden tanışın.</h1>
      <div class="mainpage-bottom-button-div">
        <router-link to="/register">
          <button class="mainpage-bottom-button">Hemen Başla!</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import CompactToday from "@/pages/components/todayItems/compactToday"
import singleGig from "@/pages/components/singleGig"
import Api from "@/utils/request"
import BlogView from "@/pages/components/BlogView"
  export default {
    components: {BlogView, CompactToday, singleGig},
    name: "src-pages-body-main-v4",
    data() {
      return {
        gigs: [],
        blogListLoader: false,
        impressionUUID:null,
        blogViews: []
      }
    },

    methods: {

      getGigsMainpage() {
        this.api.general.getGigsMainpage()
          .then(({data}) => {
            let result = data;
            if (result.success) {

              let trackGigObj = [];
              result.data.gigs.forEach((gig, gigdex) => {
                trackGigObj.push({
                  gig_id:gig.id,
                  seller_id:gig.seller_id,
                  order_id: gigdex + 1
                })
              });
              let trackingProps = {
                page:'homepage',
                placement:'default',
                info:trackGigObj,
                title: this.impressionUUID
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
              this.gigs = result.data.gigs;
            }
          });
      },




      openOnboardingModal(){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ONBOARDING,info:{url:null}});
      },



      mouseoverOnCategory(categoryId) {
        document.getElementById('category' + categoryId).style.textDecoration = "underline";
      },

      mouseoutOnCategory(categoryId) {
        document.getElementById('category' + categoryId).style.textDecoration = "none";

      },



      openBlogDetailModal(block) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.BLOG_DETAIL, info: {block}});
      },

      setWhereFrom(){
	      window.localStorage.setItem("whereFrom",  this.$store.state.componentMap.page);
	      window.localStorage.setItem("clickedFrom", 'Blog Home');
	      this.$store.state.clickedFrom = 'Blog Home';
      },

      preventClicking(event) {

        event.returnValue = false;
        return false;
      },
    },


    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.getGigsMainpage();
      Api.get('/blog-views/main')
        .then(({ data }) => {
          this.blogViews = data
        })
    }
  }
</script>

<style scoped lang="scss">




  .mainpage-header {
    padding-top: 37.3px;
    padding-right: 39px;
    padding-left: 21px;
  }

  /*body*/
  .mainpage-body {
    padding-top: 20px;

  }


  .homeH1 {

    font-size: 52px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
    margin-top: 128px;
  }

  .homeH1 span {
    font-weight: 500;
  }

  .header-info {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.33;
    text-align: center;
    color: #8b95a1;
    margin-top: 30px;
    margin-bottom: 45px;

  }

  /*get started*/
  .get-started-body {
    transition: .5s;
    margin: auto;
    border-radius: 2px;
    background-color: #ffffff;


    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  /* media queries for landing input 3/4 */
  @media screen and (max-width: 1600px) {
    .get-started-body {
      width: 825px;
      height: 97.5px;
    }

    .get-started-input {
      width: 467.25px;
      height: 52.5px;
    }

    .get-started-button {
      width: 250px;
      height: 52.5px;
    }

    .get-started-right {
      top: 48.75px;
    }
  }

  @media screen and (min-width: 1600px) {
    .get-started-body {
      width: 945px;
      height: 130px;
    }

    .get-started-input {
      width: 623px;
      height: 70px;
    }

    .get-started-button {
      width: 233px;
      height: 70px;
    }

    .get-started-right {
      top: 65px;
    }
  }

  .get-started-input {
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    font-size: 18px;
    text-align: left;
    margin: 0 30px;
    color: #a1a9a9;
    padding: 0;
    padding-left: 22px;
  }

  .get-started-right {
    position: absolute;
    display: flex;
    right: -220px;
    align-items: center;
  }

  .get-started-button {
    border-radius: 2px;
    background-image: linear-gradient(45deg, rgb(230, 50, 98), rgb(253, 64, 86));

    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    margin: 0 30px 0 0;
  }

  .get-started-button:hover {

    background-image: linear-gradient(45deg, #e63262, #e63262);

  }

  /*illustration*/
  .mainpage-illustration {
    padding-bottom: 80px;
    padding-top: 30px;
  }

  .mainpage-illustration h1 {
    font-size: 42px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
    padding-bottom: 11px;
  }

  .illustration-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .illustration-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .illustration-main-paragraph {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 22px;
  }

  .illustration-paragraph {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.44;
    text-align: center;
    color: #8b95a1;
    word-break: break-word;
    width: 370px;
    height: 152px;
    margin-top: 21px;
  }

  .mainpage-learnmore-div {
    width: 220px;
    height: 50px;
    border-radius: 2px;

    border: 1px solid rgba(75, 79, 82, .2);

    margin: auto;
  }

  .mainpage-learnmore-div:hover {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    margin: auto;
  }

  .mainpage-learnmore-div a {
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 54, 64);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
  }

  /*marketplace*/
  .mainpage-marketplace-body {
    background-color: #f4f5f7;
    padding-top: 52px;
    padding-bottom: 60px;
  }

  .mainpage-marketplace-body h1 {
    font-size: 42px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
    padding-bottom: 20px;
  }


  .marketplace-gigs-container {
    margin: 0 auto;
    width: 1170px;
    text-align: center;
  }



  .marketplace-bottom-div {
    text-align: center;
  }


  /*mainpage bottom*/
  /*bottom*/

  .mainpage-bottom {
    background-image: linear-gradient(79deg, rgba(230, 50, 98, 0.9), rgba(253, 64, 86, 0.9));
    overflow: hidden;
    position: relative;
    height: 158px;
    padding-top: 119px;
    padding-bottom: 119px;
  }

  .mainpage-bottom h1 {
    font-size: 42px;
    font-weight: 300;
    color: #ffffff;
    padding-bottom: 30px;
    margin: auto;
    text-align: center;
  }

  .mainpage-bottom-button-div {
    margin: auto;
    position: relative;
    width: 270px;
    height: 70px;

  }

  .mainpage-bottom-button {
    width: 270px;
    height: 70px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    color: #2d3640;
    border: none;
  }

  .mainpage-bottom-button:hover {
    background-color: #f6f6f6 !important;
  }

  .bottom-right {
    position: absolute;
    display: flex;
    align-items: center;
    top: 30px;
    right: -215px;
  }



  .app-logo {
    width: 120px;
    height: 29px;
  }

  .morenmore {
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 54, 64);
    width: 220px;
    height: 50px;
  }

  .morenmore:hover {
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2) !important;
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 54, 64) !important;
    width: 220px;
    height: 50px;
    background: white !important;
  }
</style>

